import React from 'react'
import styled from 'styled-components'

import Section from './Section'

import bgImage from '../resources/images/catch-phrase-bg.jpg'
import bgImage2x from '../resources/images/catch-phrase-bg@2x.jpg'
import bgImageMobile from '../resources/images/catch-phrase-bg-m.jpg'
import bgImageMobile2x from '../resources/images/catch-phrase-bg-m@2x.jpg'

import Media from '../utils/style-utils'

const Phrase = styled.h1`
  font-size: 1.5rem;
  line-height: 3rem;
  text-align: center;
  margin: 0;
  letter-spacing: 2px;
  color: white;
  font-weight: 300;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
`

const Container = styled(Section)`
  max-width: 100%;
  background-image: url(${bgImage});
  background-size: cover;

  ${Media.retina`
    background-image: url(${bgImage2x});
  `}

  ${Media.handheld`
    background-image: url(${bgImageMobile});
  `}

  ${Media.handheldRetina`
    background-image: url(${bgImageMobile2x});
  `}
`

const CatchPhrase = () => (
  <Container>
    <Phrase className="mm-text">
      လက်ရာသန့်ရှင်း &quot;
      <span className="en-text">Myo Painting</span>
      &quot;
      <br /> ကျေနပ်စေမှာ မျိုးအဖွဲ့ပါ
    </Phrase>
  </Container>
)

export default CatchPhrase
