import React from 'react'
// import { Link } from 'gatsby';
import SEO from '../components/SEO'
import Hero from '../components/Hero'
import Services from '../components/Services'
import Promises from '../components/Promises'
import CatchPhrase from '../components/CatchPhrase'
import About from '../components/About'
import Contact from '../components/Contact'
import Projects from '../components/Projects'
import Layout from '../components/Layout'
import Menu from '../components/Menu'

const IndexPage = () => (
  <Layout>
    <SEO />
    <Menu isOverlay hideLogo />
    <Hero />
    <About />
    <Services />
    <CatchPhrase />
    <Promises />
    <Projects />
    <Contact />
  </Layout>
)

/*

    <h2>
Fun Facts
    </h2>
    <p>
Founded on: 2005
    </p>
    <p>
No. of employee: 470
    </p>
    <p>
Projects: 500+
    </p>

*/

export default IndexPage
