import React from 'react'
import styled from 'styled-components'

import heroBg from '../resources/images/hero-bg-2.jpg'
import heroBg2x from '../resources/images/hero-bg-2@2x.jpg'
// import heroBgMobile from '../resources/images/hero-bg-m.jpg';
// import heroBgMobile2x from '../resources/images/hero-bg-m@2x.jpg';
import Logo from './Logo'

import Media from '../utils/style-utils'

const HeroTitle = styled.h1`
  font-size: 2.8rem;
  /* color: #f7e9c1; */
  letter-spacing: 4px;
  font-weight: 300;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
  color: #fde251;
  padding: 0.5rem;
`

const HeroSubTitle = styled.h2`
  letter-spacing: 2px;
  font-weight: 200;
  color: white;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
`

const HeroDescription = styled.h4`
  color: white;
  font-weight: 200;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
  letter-spacing: 1px;
`

const Wrapper = styled.section`
  position: relative;
  width: 100%;
  height: 450px;
  background-color: #070703;
`

const Background = styled.div`
  position: relative;
  width: 1400px;
  height: 100%;
  margin: 0 auto;
  background-image: url(${heroBg});
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #131307;

  ${Media.retina`
    background-image: url(${heroBg2x});
  `}

  ${Media.handheld`
    background-image: url(${heroBg});
    background-size: cover;
    background-position: center;
    height: 500px
    width: 100%;
  `}

  ${Media.handheldRetina`
    background-image: url(${heroBg2x});
    background-size: cover;
    background-position: center;
    width: 100%;
  `}

  ${Media.tablet`
    width: 100%;
  `}
`

const Dim = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1rem;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
`

const Hero = () => (
  <Wrapper>
    <Background>
      <Dim>
        <div style={{ marginTop: '50px' }}>
          <Logo hideName />
        </div>
        <HeroTitle>Myo Painting Group Co., Ltd</HeroTitle>
        <HeroSubTitle>Professional Painting Service in Myanmar</HeroSubTitle>
        <HeroDescription>
          For hotels, offices, condominiums, villas and other buildings
        </HeroDescription>
      </Dim>
    </Background>
  </Wrapper>
)

export default Hero
