import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

import SectionTitle from './SectionTitle'
import Paragraph from './Paragraph'

import ayeyarwadyBankImage from '../resources/images/prj-ayeyarwady-bank-sulay.jpg'
import floorEpoxyImage from '../resources/images/prj-floor-epoxy.jpg'
import houseImage from '../resources/images/prj-house-owner.jpg'
import lgShowRoomImage from '../resources/images/prj-lg-show-room.jpg'
import marbleEffectImage from '../resources/images/prj-marble-effect-work.jpg'
import novotelImage from '../resources/images/prj-novotel-hotel-inlay.jpg'
import paintingImage from '../resources/images/prj-painting-work.jpg'
import polishingImage from '../resources/images/prj-polishing-work.jpg'
import saepaingImage from '../resources/images/prj-sae-paing-development.jpg'
import vantageImage from '../resources/images/prj-vantage-tower.jpg'

const ProjectsContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;

  a {
    color: orange;
    text-decoration: none;
  }

  a:hover {
    font-weight: 500;
    text-decoration: underline;
  }

  a:visited {
    color: orange;
  }
`

const ImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const ProjectImage = styled.div`
  background-image: url(${({ bgSrc }) => bgSrc});
  background-size: cover;
  background-position: center;
  flex: 1;
  min-width: 220px;
  height: 200px;
  margin-bottom: 0;

  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.65);
    color: #fff;
    letter-spacing: 2px;
    text-align: center;
    opacity: 0;
    transition: 0.5s;
  }

  &:hover,
  &:focus {
    p {
      opacity: 1;
    }
  }
`

const GALLERY = [
  {
    title: 'Ayeyarwady Bank, Sulay, Yangon',
    src: ayeyarwadyBankImage
  },
  {
    title: 'Floor epoxy',
    src: floorEpoxyImage
  },
  {
    title: 'House painting work',
    src: houseImage
  },
  {
    title: 'LG Showroom',
    src: lgShowRoomImage
  },
  {
    title: 'Marble effect',
    src: marbleEffectImage
  },
  {
    title: 'Novotel Hotel, Inlay',
    src: novotelImage
  },
  {
    title: 'Painting work',
    src: paintingImage
  },
  {
    title: 'Polishing work',
    src: polishingImage
  },
  {
    title: 'Sae Paing Development',
    src: saepaingImage
  },
  {
    title: 'Vantage Tower',
    src: vantageImage
  }
]

export default () => (
  <ProjectsContainer>
    <SectionTitle>Projects</SectionTitle>
    <Paragraph style={{ textAlign: 'center' }} isMyanmar>
      Project ဓါတ်ပုံများကို <Link to='/projects/'>ဤစာမျက်နှာတွင်</Link>{' '}
      စုံစုံလင်လင်ကြည့်နိုင်ပါပြီ။
    </Paragraph>
    <ImageContainer>
      {GALLERY.map(({ src, title }) => (
        <ProjectImage key={title} bgSrc={src}>
          <p className='en-text'>{title}</p>
        </ProjectImage>
      ))}
    </ImageContainer>
  </ProjectsContainer>
)
