import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import Section from './Section'
import Paragraph from './Paragraph'
import Media from '../utils/style-utils'

import SectionTitle from './SectionTitle'
import safetyIcon from '../resources/images/helmet.png'
import customerServiceIcon from '../resources/images/like.png'
import trustIcon from '../resources/images/shield.png'

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`

const PromiseItemContainer = styled.div`
  text-align: center;
  width: 280px;
  word-break: keep-all;
  margin: 0;
  ${Media.handheld`
    width: 100%;
    margin: 2rem 0;
  `};

  ${Media.tablet`
    width: 100%;
    margin: 2rem 4rem;
  `}
`

const PromiseItem = ({ title, children, logo }) => (
  <PromiseItemContainer>
    <img src={logo} alt='' width='80' />
    <h3>{title}</h3>
    {children}
  </PromiseItemContainer>
)

PromiseItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  logo: PropTypes.string.isRequired
}

const PromiseContent = styled(Paragraph)`
  text-align: left;
  font-size: 0.9rem;
  line-height: 1.8rem;
  ${Media.handheld`
    text-align: center;
  `};
`

const Promises = () => (
  <Section>
    <SectionTitle>We Promise</SectionTitle>
    <Row>
      <PromiseItem title='Safety' logo={safetyIcon}>
        <PromiseContent isMyanmar>
          အထပ်မြင့် အဆောက်အဦးများ လုပ်ဆောင်ရာတွင် နိုင်ငံခြားငြမ်းများ
          အသုံးပြုခြင်း၊ <span className='en-text'>Site</span> အတွင်း{' '}
          <span className='en-text'>Safety</span> လိုအပ်ချက်များကို
          ဝန်ထမ်းများအား သင်ကြားပို့ချထားသောကြောင့် လုံခြုံမှု အာမခံချက်
          အပြည့်အဝပေးနိုင်ပါသည်။
        </PromiseContent>
      </PromiseItem>
      <PromiseItem title='Customer Service' logo={customerServiceIcon}>
        <PromiseContent isMyanmar>
          ဆက်ဆံရေးကောင်းမွန်သော ကျွမ်းကျင်လုပ်သားများဖြင့် ဖွဲ့စည်းထားသောကြောင့်
          လူကြီးမင်းတို့ လိုအပ်ချက်များကို အသေးစိတ်ဂရုစိုက်ပေးနိုင်ပြီး
          လုပ်ငန်းအကြောင်း ကောင်းမွန်စွာ ရှင်းပြပေးနိုင်သဖြင့် စိတ်ကျေနပ်မှု
          အပြည့်အဝ ရနိုင်ရန် အာမခံပါသည်။
        </PromiseContent>
      </PromiseItem>
      <PromiseItem title='Trust' logo={trustIcon}>
        <PromiseContent isMyanmar>
          အရည်သွေးမြင့် နိုင်ငံခြားဆေးများ အသုံးပြု၍ ကျွမ်းကျင်လုပ်သားများဖြင့်
          စိတ်တိုင်းကျ ဆောင်ရွက်ပေးနေသောကြောင့် လူကြီးမင်းတို့၏ ဟိုတယ်၊ စက်ရုံ၊
          အဆောက်အအုံ နှင့် အခြားလုပ်ငန်းများကို ယုံကြည်စိတ်ချစွာ
          အပ်နှံနိုင်ပါသည်။
        </PromiseContent>
      </PromiseItem>
    </Row>
  </Section>
)

export default Promises
