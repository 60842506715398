import React from 'react'

import Section from './Section'
import SectionTitle from './SectionTitle'
import Paragraph from './Paragraph'

const About = () => (
  <Section>
    <SectionTitle>We Are</SectionTitle>
    <Paragraph className="mm-text" isMyanmar>
      ကျွန်တော်တို့ <span className="en-text">Myo Painting Group Co., Ltd</span>{' '}
      သည် ၁၉၉၉ ခုနှစ်တွင် ဆေးသုတ်ခြင်းလုပ်ငန်းများ စတင်လုပ်ကိုင်ခဲ့ပြီး ၂၀၀၄
      ခုနှစ်တွင် ကုမ္ပဏီ စတင်တည်ထောင်ခဲ့ပါသည်။ ဝါရင့် ခေါင်းဆောင်များ၊
      ကျွမ်းကျင်လုပ်သားများဖြင့် ဖွဲ့စည်းထားပြီး နိုင်ငံတကာခေတ်မီ
      အထပ်မြင့်အဆောက်အဦးများကို ဆေးသုတ်ခြင်းလုပ်ငန်းများ ဆောင်ရွက်ပေးသော
      ကုမ္ပဏီဖြစ်ပါသည်။ ကျွန်တော်တို့ရဲ့ အဓိက ပန်းတိုင်ကတော့ အရည်သွေးပြည့်ဝပြီး
      သေသပ်လှပသော လက်ရာများကို ဝန်ဆောင်မှုပေးသည့် နံပါတ်တစ် ကုမ္ပဏီတစ်ခုအဖြစ်
      ရပ်တည်ရန်ဖြစ်ပါသည်။ သင့်တင့်သော စျေးနှုန်းများနှင့် လူကြီးမင်းတို့
      စိတ်ကျေနပ်မှုရရှိစေရန် တိကျ၊ မြန်ဆန်စွာဖြင့် လုပ်ငန်းများကို
      အချိန်မီပြီးစီးစေရန် အာမခံပါသည်။
    </Paragraph>
  </Section>
)

/*

Myo Painting Group Co., Ltd ကို 2005
      ခုနစ်တွင်စတင်တည်ထောင်ခဲ့ပါသည်။ကျွန်ုပ်တို့ Company ရဲ့ Vision ကတော့
      ဝါရင့်သော Section Head များနှင့် ကျွမ်းကျင်သော လုပ်သားများဖြင့် ခေတ်မီသော
      နိုင်ငံတကာ အထပ်မြင့်အဆောက်အဦး များကို ဆေးသုတ်ခြင်းလုပ်ငန်း ဆောင်ရွက်ပေးသော
      Company ဖြစ်ပါသည်။ကျွနု်ပ်တို့ Companyရဲ့ Mission ကတော့ Project တခုကို
      အရေအသွး ပြောစရာမလိုအောင် လှပသေသပ်ရန်နှင့် Customer
      များစိတ်ကျေပ်မူရရှိရန်အဓိက ရည်ရွယ်ပါသည်။ ကျွနု်ပ်တို့၏ Myo Painting Group
      Co., Ltd. သည် ခေတ်မီသော အဆောက်အဦးများ ၊ ဟိုတယ်များကို ဈေးနှုန်းသင့်တင့်
      စွာဖြင့် ဆေးသုတ်ခြင်းလုပ်ငန်းများ ဆောင်ရွက်ပေးပါသည်။
      လုပ်ငန်းလည်ပတ်စဉ်အတွင်း Worker များဘေးအန္တာရာယ် ကင်းရှင်းစွာ
      ဆောင်ရွက်ပေးသည်။Customer များစိတ်ကျေနပ်မှုရရှိစေရန် တိကျ၊ မြန်ဆန်စွာဖြင့်
      အချိန်မီပြီးစီးစေရန် ဆောင်ရွက်ပေးပါသည်။ကျွနု်ပ်တို့ Myo Painting Group
      Co., Ltd. သည် Project တခုကို ပြီးဆုံးသည့်အထိ လိုအပ်သော Knowledge များ Site
      အတွင်း Safety လိုအပ်ချက်များကို ဝန်ထမ်းများအားသင်ကြားပို့ချထားသောကြောင့်
      ဝန်ထမ်းများ အတွက် Safety အာမခံချက် ရှိခြင်း။ Customer များအဆင်ပြေစေရန်
      လူတန်းစားမရွေးအသုံးပြုနိုင်သော ဆေးများဖြင့် စိတ်တိုင်းကျ ဆေးသုတ်ပေးခြင်း ။
      Customer ၏ လိုအပ်ချက်များကို အသေးစိတ်ဂရုစိုက်ပေးခြင်း ။ ဝန်ထမ်းများ၏
      Service နှင့် Customer ဆက်ဆံရေးများကောင်းမွန်သောကြောင့် လုပ်ငန်းအကြောင်း
      Customer အားကောင်းမွန်စွာရှင်ပြနိုင်ခြင်း Professional Services For
      Painting , Spray Paint , Sand Texture , Hi - Tech Paint,Industrial Paint ,
      Spray Polishing , Waterproofing , Epoxy Floor Paint လုပ်ငန်းများကို
      အရေအသွေး ပြောစရာမလိုအောင်လှပသေသပ်စွာ ဆောင်ရွက်ပေးပါသည်။ အထပ်မြင့်
      အဆောက်အဦးများ လုပ်ဆောင်ရာတွင် Safety အပြည့်များ ဝတ်ဆင်၍ နိုင်ငံခြား
      ငြမ်းများ အသုံးပြုကာ ဆေးသုတ်ခြင်းလုပ်ငန်းများ
      လုပ်ဆောင်ပါသည်။လူကြီးမင်းတို့ Hotel , Factory , Buildings , Other Project
      များကို နိုင်ငံခြားဆေးများအသုံးပြု၍ ကျွမ်းကျင်သောလုပ်သားများဖြင့်
      စိတ်တိုင်းကျဆောင်ရွက်ပေးသောကြောင့် ယုံကြည်စိတ်ချစွာ
      လုပ်ငန်းအပ်နှံနိုင်ပါသည်။
*/

export default About
