import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import SectionTitle from './SectionTitle'
import Section from './Section'
import Media from '../utils/style-utils'

import paintBrushIcon from '../resources/images/paint-brush.png'
import sprayIcon from '../resources/images/spray-paint.png'
import sandIcon from '../resources/images/sand.png'
import chemistryIcon from '../resources/images/chemistry.png'
import bucketIcon from '../resources/images/bucket.png'
import factoryIcon from '../resources/images/factory.png'
import waterIcon from '../resources/images/watering.png'
import sprayGunIcon from '../resources/images/spray-gun.png'

const Row = styled.div`
  display: flex;
  justify-content: space-around;
  width: 890px;
  margin: 0 auto;
  ${Media.handheld`
    display: block;
  `};
  ${Media.tablet`
    width: 100%;
  `}
`
const Col = styled.div`
  margin: 0 2rem;
`

const ItemContainer = styled.div`
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  margin: 1.5rem 0;
`

const ItemTitle = styled.h3`
  width: 210px;
  margin: 0;
  font-weight: 400;
  letter-spacing: 1px;
  color: white;
`

const ItemIconContainer = styled.div`
  width: 80px;
  height: 80px;
  border: 1px solid #ccc;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
`
const ItemIcon = styled.img`
  width: 45px;
  margin: 0;
`
const ServiceItem = ({ title, icon, alt }) => (
  <ItemContainer>
    <ItemIconContainer>
      <ItemIcon src={icon} alt={alt} />
    </ItemIconContainer>
    <ItemTitle>{title}</ItemTitle>
  </ItemContainer>
)

ServiceItem.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
}

const ServiceSection = styled(Section)`
  max-width: 100%;
  background: #485563; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #29323c,
    #485563
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #29323c,
    #485563
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`

const Services = () => (
  <ServiceSection>
    <SectionTitle style={{ color: 'white' }}>Services</SectionTitle>
    {/* <Desktop> */}
    <Row>
      <Col>
        <ServiceItem title='Painting' icon={paintBrushIcon} alt='Paint brush' />
        <ServiceItem
          title='Spray Paint'
          icon={sprayIcon}
          alt='Green color spray can'
        />
        <ServiceItem
          title='Sand Texture'
          icon={sandIcon}
          alt='Sand castle with red flags'
        />
        <ServiceItem
          title='Hi-Tech Paint'
          icon={chemistryIcon}
          alt='Chemistry compounds'
        />
      </Col>
      <Col>
        <ServiceItem
          title='Industrial Paint'
          icon={factoryIcon}
          alt='Factory'
        />
        <ServiceItem
          title='Spray Polishing'
          icon={sprayGunIcon}
          alt='Spray gun'
        />
        <ServiceItem
          title='Water Proofing'
          icon={waterIcon}
          alt='Water fountain'
        />
        <ServiceItem
          title='Epoxy Floor Paint'
          icon={bucketIcon}
          alt='bucket and mop clean'
        />
      </Col>
    </Row>
    {/* </Desktop> */}
  </ServiceSection>
)

// Painting
// Spray Paint
// Sand Texture
// Hi-Tech Paint
// Industrial Paint
// Spray Polishing
// Waterproofing
// Epoxy Floor Paint

export default Services
